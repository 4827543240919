// Import everything from autoload folder
import './autoload/_bootstrap.js'; import './autoload/ada-compliance.js'; // eslint-disable-line

/* global triumph */
// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import Cookies from 'js-cookie';
import objectFitImages from 'object-fit-images';
// import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 100 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        // TODO: replace with bootstrap toggle
        // $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  $('#primary-navbar').on('shown.bs.collapse', function () {
    if ($(window).width() <= 768) {
      $(this).addClass('menu-left-slide');
    }
  });
  $('#primary-navbar').on('hidden.bs.collapse', function () {
    if ($(window).width() <= 768) {
      $(this).removeClass('menu-left-slide');
    }
  });

  $('#primary-navbar').on('shown.bs.collapse', function () {
    if ($(window).width() <= 768) {
      $('body').addClass('lock-scroll');
    }
  });
  $('#primary-navbar').on('hidden.bs.collapse', function () {
    // if ($(window).width() <= 768) {
    $('body').removeClass('lock-scroll');
    // }
  });
  $('.menu-item.dropdown').on('show.bs.dropdown', function () {
    $(this).children('ul').slideDown();
  });
  $('.menu-item.dropdown').on('hide.bs.dropdown', function () {
    $(this).children('ul').slideUp();
  });

  let agreement = $('#agreement');
  let agreementJoin = $('.franchise-join__button');
  agreement.on('change', function () {
    if ($(this).is(':checked')) {
      // agreementJoin.prop('disabled', false);
    } else {
      // agreementJoin.prop('disabled', true);
    }
  });
  agreementJoin.on('click', function (e) {
    e.preventDefault();
    if (agreement.is(':checked')) {
      $('.missing-field').hide();
      $('.franchise-join__form-section').slideDown().addClass('form-visible');
      $('html, body').animate(
        {
          scrollTop: $('.franchise-join__form-section').offset().top - 40,
        },
        700
      );
    } else {
      $('.missing-field').show();
      $('.franchise-join__form-section').slideUp().removeClass('form-visible');
    }
  });
  //  Countries tabs
  let countriesBtn = $('.js-countries-btn');
  let countriesClose = $('.js-countries-close');
  let countriesTabs;
  countriesBtn.on('click', function (e) {
    e.preventDefault();
    countriesTabs = $(this).closest('.countries').find('.js-countries-tabs');
    if (countriesTabs.hasClass('active')) {
      countriesTabs.removeClass('active');
      countriesTabs.slideUp();
    } else {
      countriesTabs.addClass('active');
      countriesTabs.slideDown();
    }
  });
  countriesClose.on('click', function (e) {
    e.preventDefault();
    countriesTabs = $(this).closest('.countries').find('.js-countries-tabs');
    countriesTabs.removeClass('active');
    countriesTabs.slideUp();
  });
  //filters tabs
  let filtersBtn = $('.js-filters-btn');
  let filtersClose = $('.js-filters-close');
  let filtersTabs = $('.js-filters-tabs');
  filtersBtn.on('click', function (e) {
    e.preventDefault();
    if (filtersTabs.hasClass('active')) {
      filtersTabs.removeClass('active');
      filtersTabs.slideUp();
    } else {
      filtersTabs.addClass('active');
      filtersTabs.slideDown();
    }
  });
  filtersClose.on('click', function (e) {
    e.preventDefault();
    filtersTabs.removeClass('active');
    filtersTabs.slideUp();
  });
  //languages
  //  Countries tabs
  let languagesBtn = $('.js-languages-btn');
  let languagesClose = $('.js-languages-close');
  let languagesTabs = $('.js-languages-container');
  languagesBtn.on('click', function (e) {
    e.preventDefault();
    if (languagesTabs.hasClass('active')) {
      languagesTabs.removeClass('active');
      languagesTabs.slideUp();
    } else {
      languagesTabs.addClass('active');
      languagesTabs.slideDown();
    }
  });
  languagesClose.on('click', function (e) {
    e.preventDefault();
    languagesTabs.removeClass('active');
    languagesTabs.slideUp();
  });

  $('.slider-section__slider').slick({
    slidesToShow: 3.25,
    infinite: false,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    prevArrow:
      "<button type='button' class='slick-prev slick-arrow pull-left'><i class='fa fa-chevron-left' aria-hidden='true'></i></button>",
    nextArrow:
      "<button type='button' class='slick-next slick-arrow pull-right'><i class='fa fa-chevron-right' aria-hidden='true'></i></button>",
    rows: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          autoplay: false,
          arrows: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          autoplay: false,
          arrows: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.1,
          slidesToScroll: 1,
          autoplay: false,
          arrows: true,
        },
      },
    ],
  });

  $('#careers-wrapper-js').on(
    'click',
    'a[data-filter], .pagination a',
    function (event) {
      if (event.preventDefault) {
        event.preventDefault();
      }
      let $country = $('#careers-wrapper-js').data('country');
      let $this = $(this);
      let $page;
      let $active = {};
      let $allTermsObjects;
      if ($this.data('filter')) {
        /**
         * Click on tag cloud
         */
        if ($this.parent('li').hasClass('active')) {
          $this.parent('li').removeClass('active');
        } else {
          // $this.closest('ul').find('.active').removeClass('active');
          $this.parent('li').addClass('active');
        }
        $page = $this.data('page');
      } else {
        /**
         * Click on pagination
         */
        $page = parseInt($this.attr('href').replace(/\D/g, ''));
        $this = $('.nav-filter .active a');
      }
      $allTermsObjects = $('.nav-filter').find('li.active');
      if ($allTermsObjects.length) {
        $.each($allTermsObjects, function (index, term) {
          let $a = $(term).find('a');
          let $tax = $a.data('filter');
          let $slug = $a.data('term');

          if ($tax in $active) {
            $active[$tax].push($slug);
          } else {
            $active[$tax] = [];
            $active[$tax].push($slug);
          }
        });
      }
      let $params = {
        page: $page,
        terms: $active,
        country: $country,
        qty: $this.closest('#careers-wrapper-js').data('paged'),
      };

      // Run query
      get_posts($params);
    }
  );

  // if ($('body').hasClass('page-template-template-careers-list')) {
  //   if (typeof Cookies.get('careers') !== 'undefined') {
  //     let $careerCookie = JSON.parse(Cookies.get('careers'));
  //     if (
  //       $careerCookie['country'] != $('#careers-wrapper-js').data('country') ||
  //       $careerCookie['country'] == ''
  //     ) {
  //       Cookies.remove('careers');
  //       console.log('true');
  //     }
  //   }
  // }
  // if ($('body').hasClass('page-template-template-careers-list')) {
  //   if (window.PerformanceNavigationTiming) {
  //     console.info(window.performance.getEntriesByType('navigation')[0].type);
  //     if (
  //       window.performance.getEntriesByType('navigation')[0].type ===
  //       'reload' &&
  //       typeof Cookies.get('careers') !== 'undefined'
  //     ) {
  //       // get_posts();
  //     }
  //   }
  // }
  function get_posts($params) {
    let $container = $('#careers-wrapper-js');
    let $content = $container.find('.content');
    let $status = $container.find('.status');

    // $status.text('Loading posts ...');

    $.ajax({
      url: triumph.ajax_url,
      data: {
        action: 'filter',
        nonce: triumph.nonce,
        params: $params,
      },
      type: 'post',
      dataType: 'json',
      beforeSend: function () {
        $('#loader').removeClass('hidden');
        if (typeof $params !== 'undefined') {
          Cookies.set('careers', JSON.stringify($params));
        }
      },
      success: function (data) {
        if (data.status === 200) {
          $content.html(data.content);
        } else if (data.status === 201) {
          $content.html(data.message);
        } else {
          $status.html(data.message);
        }
      },
      error: function () {
        // $status.html(textStatus);
        //
        // console.log(XMLHttpRequest);
        // console.log(textStatus);
        // console.log(errorThrown);
      },
      complete: function () {
        $('#loader').addClass('hidden');
        // let msg = textStatus;
        //
        // if (textStatus === 'success') {
        //   msg = data.responseJSON.found;
        // }
        //
        // $status.text('Posts found: ' + msg);
        /*console.log(data);
        console.log(textStatus);*/
      },
    });
  }
  $('.header__menus').mouseenter(function () {});

  $(document).click(function (event) {
    let clickover = $(event.target);
    let _opened = $('#primary-navbar').hasClass('navbar collapse show');
    if (
      _opened === true &&
      !clickover.hasClass('navbar-toggler') &&
      !clickover.hasClass('js-wpml-ls-item-toggle') &&
      !clickover.hasClass('wpml-ls-flag') &&
      !clickover.hasClass('wpml-ls-native')
    ) {
      $('button.navbar-toggler').click();
    }
  });

  let currentPage = 1;
  let loadPage = false;
  function get_products($pageData) {
    if (typeof $pageData === 'undefined') {
      $pageData = 1;
      currentPage = 1;
      loadPage = false;
    } else {
      loadPage = true;
    }
    let $active = {};
    let $allTermsObjects = $('.product-pass__filters').find('li.active');
    if ($allTermsObjects.length) {
      $.each($allTermsObjects, function (index, term) {
        let $a = $(term).find('a');
        let $tax = $a.data('filter');
        let $slug = $a.data('term');

        if ($tax in $active) {
          $active[$tax].push($slug);
        } else {
          $active[$tax] = [];
          $active[$tax].push($slug);
        }
      });
    }
    let $params = {
      page: $pageData,
      terms: $active,
      search: $('.product-pass__search-input').val(),
    };

    let $content = $('.product-pass__body');
    $.ajax({
      url: triumph.ajax_url,
      data: {
        action: 'product_filter',
        nonce: triumph.nonce,
        params: $params,
      },
      type: 'post',
      dataType: 'json',
      beforeSend: function () {
        $('#loader').removeClass('hidden');
      },
      success: function (data) {
        if (loadPage === true) {
          $content.append(data.content);
        } else {
          $content.html(data.content);
        }
        if (data.hide_load == true) {
          $('.load-more-button').hide();
        } else {
          $('.load-more-button').show();
        }
      },
      error: function () {},
      complete: function () {
        $('#loader').addClass('hidden');
      },
    });
  }

  if ($('body').hasClass('page-template-template-product-pass')) {
    $(document).on('click', '.product-pass__link', function (e) {
      e.preventDefault();
      $(this).children('a').toggleClass('active');
      $(this)
        .parents('.product-pass__item')
        .toggleClass('active')
        .find('.product-pass__item-content')
        .slideToggle('400', 'linear');
    });
    $(document).on('click', '.js-close', function (e) {
      e.preventDefault();
      $(this)
        .parents('.product-pass__item')
        .toggleClass('active')
        .find('.product-pass__item-content')
        .slideToggle('400', 'linear');
    });

    $('.product-pass__filter-drop-button').on('click', function () {
      $(this).toggleClass('active');
      $('.product-pass__filters').fadeToggle();
    });
    $('.product-pass__filter').on('click', 'a[data-filter]', function (event) {
      event.preventDefault();
      let $this = $(this);
      if ($this.data('filter')) {
        if ($this.parent('li').hasClass('active')) {
          $this.parent('li').removeClass('active');
        } else {
          $this.parent('li').addClass('active');
        }
      }
    });

    $(document).on('click', '.load-more-button', function (e) {
      e.preventDefault();
      currentPage++;
      get_products(currentPage);
    });
    $(document).on(
      'click',
      '.js-product-filter, .product-pass__search-button',
      function (e) {
        e.preventDefault();
        get_products();
      }
    );
    $(document).on('click', '.js-clear-filters', function (e) {
      e.preventDefault();
      let activeFilters = $('.product-pass__filters').find('li.active');
      if (activeFilters.length) {
        $.each(activeFilters, function () {
          $(this).removeClass('active');
        });
      }
      get_products();
    });
    $(document).on('keypress', '.product-pass__search-input', function (e) {
      if (e.which == 13) {
        get_products();
      }
    });
  }
  $(document).on('click', '.readmore-button', function () {
    $(this)
      .toggleClass('active')
      .siblings('.readmore-content-hide')
      .slideToggle();
  });
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
  let applicationTitle = $('.application__form').data('title');
  let applicationCountry = $('.application__form').data('country');
  let applicationType = $('.application__form').data('type');
  $('.js-career-title input').val(applicationTitle);
  $('.js-career-country input').val(applicationCountry);
  $('.js-german-retail input').val(applicationType);
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
